// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-dostavka-i-oplata-js": () => import("./../../../src/pages/dostavka-i-oplata.js" /* webpackChunkName: "component---src-pages-dostavka-i-oplata-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-o-kompanii-js": () => import("./../../../src/pages/o-kompanii.js" /* webpackChunkName: "component---src-pages-o-kompanii-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-templates-banya-js": () => import("./../../../src/templates/banya.js" /* webpackChunkName: "component---src-templates-banya-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */)
}

